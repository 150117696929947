import { useEffect, useState } from "react";

export default function useHost() {
  const [host, setHost] = useState("");

  useEffect(() => {
    // @ts-ignore
    setHost(window.ENV.BACKEND_HOST);
  }, []);

  if (typeof process !== "undefined" && process.env.BACKEND_HOST) {
    return process.env.BACKEND_HOST;
  }

  return host;
}
